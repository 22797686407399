import 'bootstrap'
import "@fortawesome/fontawesome-free/css/all"
import '../stylesheets/landingpage/all'

// document.addEventListener("turbo:load", () => {
//   document.getElementById('cta-top')?.addEventListener('click', (e) => {
//     ga('send', 'event', 'landingpage', 'click CTA top')
//   })
//   document.getElementById('cta-bottom')?.addEventListener('click', (e) => {
//     ga('send', 'event', 'landingpage', 'click CTA bottom')
//   })
// })